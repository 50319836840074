<template>
  <div v-infinite-scroll="getVideos" infinite-scroll-immediate-check="false" infinite-scroll-disabled="reloadBusy" id="qtview">
    <v-img :src="community.banner_url" :min-height="bannerMinHeight" :max-height="bannerMaxHeight">
    </v-img>

    <!-- ### SUBMISSION OR LOGIN ### -->
    <v-dialog v-model="vDialog" width="400px">
      <SubmissionCard v-if="userDetails"
        v-on:upload-video="uploadVideo"
        :titleLabel="subpageData.submitTitleLabel"
        :inputLabel="subpageData.submitInputLabel"
        :userTitleLabel="subpageData.submitUserTitleLabel"
        :errorLabel="subpageData.submitErrorLabel"
        :categoryItems="submitCategories"
        :validUrlPattern="subpageData.validUrlPatterns"
        :contentType="contentType"
      >
      </SubmissionCard>
      <LoginCard v-else>
      </LoginCard>
    </v-dialog>

    <!-- ### COMMUNITY CARD ### -->
    <v-card class="mb-1">
      <v-container class="py-0" style="max-width: 1100px;">
        <v-card-title class="pb-1 pl-0">
          <div class="mr-4 mt-n1" style="width: 80px; height: 80px; cursor: pointer;" @click="openLink(liveLink)">
            <v-avatar size="80" :style="'border-style: solid; border-width: 2px;' + liveProps.borderColorStyle">
              <img :src="community.sq_avi_url" :alt="community.name">
            </v-avatar>
            <div class="live-marker" v-if="liveProps.isLive">
              Live
            </div>
          </div>
          <h2 class="mt-n1">{{ community.name }}</h2>
          <v-spacer></v-spacer>
          <v-switch
            v-if="isMod"
            :class="$vuetify.breakpoint.xs ? 'my-auto ml-3 mt-4' : 'my-auto ml-3'"
            hide-details
            v-model="enableModView"
            label="Mod"
          >
          </v-switch>
        </v-card-title>

        <v-card-text v-if="subpageData.description" class="pa-0 mt-3">
          <span v-if="subpageData.route === 'santa'" style="color: #b8b8b8;  font-size: 16px;">
            Help QT figure out who deserves a Christmas gift!<br>Submit clips from throughout the year and we'll decide who's been naughty or nice on stream<br><br>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  style="font-weight: bold"
                >Hover over this to see who will be judged 🎅</span>
              </template>
              <span style="font-size: 16px">Brittt<br>erobb221<br>HasanAbi<br>Ludwig<br>Maya<br>Myth<br>Nmplol<br>prezoh<br>QTCinderella<br>Squeex<br>Stanz<br>willneff
              </span>
            </v-tooltip>
          </span>
          <span v-else style="color: #b8b8b8;  font-size: 16px; white-space: pre-line;">
            {{ subpageData.description }}
          </span>
        </v-card-text>

        <!-- SOCIAL ICONS -->
        <v-card-text class="pl-0 mt-3 pb-0 pt-0" v-if="community.data && community.data.social_links">
          <v-btn icon class="mr-1" v-for="social in community.data.social_links" :key="social.icon" :href="social.link" target="_blank">
            <v-icon>
              {{ social.icon }}
            </v-icon>
          </v-btn>
        </v-card-text>

        <!-- :to="'/' + gameName + '/' + item.route" -->
        <v-card-actions class="py-0 pl-0 mt-2">
          <v-tabs v-model="tabModel" color="white" active-class="white--text" :show-arrows="true">
            <v-tab v-for="(item, index) in community.subpages" :key="index" :value="item.order">{{ item.name }}</v-tab>
          </v-tabs>
          <v-spacer></v-spacer>

          <v-btn outlined :to="'/leaderboard/' + community.routeParam" color="" class="mb-1" v-if="$vuetify.breakpoint.smAndUp">
            <v-icon left>
              mdi-podium
            </v-icon>
            <span>
              Leaderboard
            </span>
          </v-btn>
        </v-card-actions>
      </v-container>
      </v-card>
    <v-container style="max-width: 1100px;">
      <v-card outlined class="mb-4" v-if="subpageData.route === 'emotes'">
        <v-card-title>
          Emote Submission
        </v-card-title>
        <v-card-text>
          <span style="font-weight: bold">Rules:</span><br>
          <ul>
            <li>Standard emotes must be in a .png format, animated emotes in a .gif format</li>
            <li>You will either need a single PNG / GIF between 112 x 112px and 4096 x 4096px, or three sizes: 28 x 28px, 56 x 56px and 112 x 112px</li>
            <li>The file size cannot exceed 1MB</li>
          </ul>
          <br><span style="font-weight: bold">How to Submit:</span>
          <ul>
            <li>Upload your emote to Imgur (you don't need to log in or create an imgur account): <a target="_blank" href="https://imgur.com/upload">Imgur Upload</a></li>
            <li>Give your emote a title</li>
            <li>Copy the link and submit here using the <v-icon>mdi-plus</v-icon> button</li>
          </ul>
          <br><span style="font-weight: bold">The contest has ended</span>, but if you have an emote you can still post it here!
          <br>Don't forget to vote on submissions you like!
          &nbsp;&nbsp;<img style="vertical-align: middle; margin-bottom: 0.2em;" width="28px" height="28px" src="@/assets/qtcLove.png">
        </v-card-text>
      </v-card>
      <!-- <v-chip-group
        v-if="community[`sec_cat_enabled_${contentType}`]"
        v-model="secCategory"
        mandatory
        active-class="secondary"
        class="my-2"
        @change="categoryChange"
      >
        <v-chip
          v-for="cat in secondaryCategories"
          :key="cat.category_id"
          :value="cat.category_id"
          style="border-style: solid; border-color: #414141; border-width: 1px;"
          color="#272727"
        >
          {{ cat.name }}
        </v-chip>
      </v-chip-group> -->

      <!-- ### CONTENT TOOLBAR ### -->
      <v-toolbar outlined rounded dense color="">

          <!-- @@@@@@@@@@@@@   DESKTOP   @@@@@@@@@@@@@  -->
          <v-chip-group
            class="mr-4"
            v-if="!$vuetify.breakpoint.xs"
            mandatory
            active-class="secondary"
            v-model="sortBy"
            v-on:change="routeSelectorChange()"
          >
            <v-chip
              color="transparent"
              v-for="(item, index) in sortByItems"
              :key="index"
              :value="item.value"
            >
              <v-icon left small>
                {{ item.icon }}
              </v-icon>
              {{ item.label }}
            </v-chip>
          </v-chip-group>

          <!-- @@@@@@@@@@@@@   MOBILE   @@@@@@@@@@@@@  -->

          <!-- @@@@@@@@@@@@@   sortBy   @@@@@@@@@@@@@  -->
          <v-menu v-else bottom :offset-y="true" transition="slide-y-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-chip v-on="on" v-bind="attrs"  class="mr-3" color="">
                <v-icon left small>
                  {{ sortByItems[sortBy].icon }}
                </v-icon>
                {{ sortByItems[sortBy].label }}
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-chip>
            </template>

            <v-list class="cursor">
              <v-list-item-group v-model="sortBy" mandatory v-on:change="routeSelectorChange()">
                <v-list-item v-for="(sort, index) in sortByItems" :key="index" :value="sort.value">
                  <v-icon left small>
                    {{ sort.icon }}
                  </v-icon>
                  <v-list-item-title style="font-size: 14px;">{{ sort.label }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>

          <!-- @@@@@@@@@@@@@   time, controlled by timeDisplay   @@@@@@@@@@@@@  -->
          <v-menu bottom :offset-y="true" transition="slide-y-transition" class="mr-1" color="">
            <template v-slot:activator="{ on, attrs }">
              <v-chip :class="timeDisplay" v-on="on" v-bind="attrs" color="">
                {{ time.label }}
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-chip>
            </template>

            <v-list class="cursor">
              <v-list-item-group v-model="time" mandatory v-on:change="routeSelectorChange()">
                <v-list-item v-for="(item, index) in timeItems" :key="index" :value="item">
                  <v-list-item-title style="font-size: 14px;">{{ item.label }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>

          <!-- @@@@@@@@@@@@@   category   @@@@@@@@@@@@@  -->
          <v-menu bottom :offset-y="true" transition="slide-y-transition" class="mr-1" color="">
            <template v-slot:activator="{ on, attrs }">
              <v-chip class="chip-overflow mr-4" v-on="on" v-bind="attrs" >
                <span v-if="shortCategory">
                  {{ category.name.substr(0, 4) + '...' }}
                </span>
                <span v-else>
                  {{ category.name }}
                </span>
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-chip>
            </template>

            <v-list class="cursor">
              <v-list-item-group v-model="category" mandatory v-on:change="reloadContent()">
                <v-list-item v-for="(cat, index) in categories" :key="index" :value="cat">
                  <v-list-item-title style="font-size: 14px;">{{ cat.name }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>

          <v-chip
            v-if="$vuetify.breakpoint.smAndUp && subpageData.data.allow_watched"
            v-model="subpageData.data.is_watched_tab"
            @click="subpageData.data.is_watched_tab = !subpageData.data.is_watched_tab; reloadContent()"
            active-class="secondary"
          >
            {{subpageData.data.watched_data.button_text}}
          </v-chip>

        <v-spacer></v-spacer>

        <v-btn
          fab
          light
          small
          color="white"
          @click="setVDialog(true)"
          v-if="subpageData.submitTitleLabel"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>

      <div style="display: flex;" id="qtview">
        <v-col class="ma-0 pa-0" v-if="(contentType === 'clips' && community.game_id == 3) || contentType === 'santa'" cols=2>
          <v-select
            class="mt-4"
            outlined
            hide-details
            dense
            placeholder="Filter by Creator..."
            :items="creatorList"
            v-model="creatorFilter"
            @change="reloadContent"
            :menu-props="{ 'offset-y': true, 'transition': 'slide-y-transition' }"
            width="170px"
            height="40px"
            style="width: 170px; font-size: 12px !important;"
          >
          </v-select>
        </v-col>

        <v-col class="ma-0 pa-0" v-else-if="contentType === 'videos'">
          <v-select
            class="mt-4"
            outlined
            hide-details
            dense
            placeholder="Filter by Duration..."
            :items="durationItems"
            v-model="duration"
            @change="reloadContent"
            :menu-props="{ 'offset-y': true, 'transition': 'slide-y-transition' }"
            width="170px"
            style="width: 170px; font-size: 12px !important;"
          >
          </v-select>
        </v-col>

        <v-col class="pr-0 mb-0 mt-1" cols=2>
          <v-menu
            ref="menu"
            v-model="datePickerMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              depressed
              outlined
              width="170px"
              height="40px"
              color="#858586"
              style="border-width: thin; border-color: rgb(118,118,118,0.5); font-size: 12px; font-family: Roboto, sans-serif; letter-spacing: normal;"
              class="pr-3 pl-2 pb-0 mb-n2 text-none font-weight-block"
              v-if="!$vuetify.breakpoint.mdAndDown"
            >
            Filter by Date...
            <v-spacer></v-spacer>
              <v-icon small color="white">
                mdi-calendar
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
            <v-date-picker
              v-model="datePickerDate"
              @input="dateInput"
              :active-picker.sync="activePicker"
              :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)"
              min="2019-05-11"
              :show-current="false"
              range
            ></v-date-picker>
            </v-list-item>
            <v-list-item class="mt-3">
            <v-text-field
              v-model="fromDateInput"
              ref="fromDateInput"
              label="From"
              hint="yyyy-mm-dd"
              persistent-hint
              :rules="[datePickerInputRule]"
            >
            </v-text-field>
            </v-list-item>
            <v-list-item>
            <v-text-field
              v-model="toDateInput"
              label="To"
              ref="toDateInput"
              hint="yyyy-mm-dd"
              persistent-hint
              :rules="[datePickerInputRule]"
            >
            </v-text-field>
            </v-list-item>
            <div style="display: flex;">
              <v-spacer></v-spacer>
              <v-btn
                @click="datePickerCancel"
              >
                Cancel
              </v-btn>
              <v-btn
                class="ml-2 mr-4"
                :color="$vuetify.theme.dark ? 'white' : 'black'"
                :dark="!$vuetify.theme.dark"
                :light="$vuetify.theme.dark"
                @click="datePickerApply"
              >
                Apply
              </v-btn>
            </div>
          </v-list>
          </v-menu>
          <v-chip v-if="showDateChip" class="mt-4 mb-0" style="width: 100%;" close @click:close="datePickerReset">
            <p class="ma-0 pa-0" style="font-size: 11.5px;">
              {{datePickerDate[0]}} - {{datePickerDate[1]}}
            </p>
            <!-- <v-icon small color="red" class="ml-1">
              mdi-close
            </v-icon> -->
          </v-chip>
        </v-col>

        <v-spacer></v-spacer>

        <v-switch
          class="ml-3 mb-1 mt-4"
          hide-details
          v-model="playerSwitch"
          label="Disable Player"
          v-if="!this.$vuetify.breakpoint.mdAndDown"
        >
        </v-switch>
      </div>

      <span v-if="subpageData.route === 'emotes'">
        <v-switch
          class="ml-3 mb-4 mt-4"
          hide-details
          v-model="sizeSwitch"
          label="Large View"
        >
        </v-switch>

        <v-btn-toggle class="mb-4" v-model="layout" v-if="isMod" mandatory>
          <v-btn small>
            Normal
          </v-btn>
          <v-btn small>
            Single
          </v-btn>
          <!-- <v-btn small>
            Secret
          </v-btn> -->
          <!-- <v-btn small>
            Secret Single
          </v-btn> -->
        </v-btn-toggle>
        <v-btn small @click="closeSubs" v-if="isMod" class="ml-12">Close Submissions</v-btn>
      </span>

      <v-tabs-items v-model="contentGridType" v-if="!errorState && posts.length !== 0" color="#0e0e10" style="background-color: #0e0e10;">
        <!-- <v-tab-item v-for="item in community.subpages" :key="item.order" :value="item.type"> -->
        <v-tab-item :key="0" :value="0">
      <!-- ### CONTENT GRID ### -->
      <!-- contentRoute should be bound to the value of the v-selector thing for clips / videos-->
          <v-row wrap align-content-start class="mt-1">
            <v-col v-for="(post, index) in posts" :key=index class="three-cols-b two-cols-b one-col-b">
              <VideoCard
                v-on:content-deleted="contentDeleted(index, post.postId)"
                v-on:mark-watched="markWatched(index, post.postId, false, ...arguments)"
                v-on:mark-hidden="markWatched(index, post.postId, true)"
                v-on:primary-chip-selected="primaryChipSelected(post.category_id)"
                v-on:secondary-chip-selected="secondaryChipSelected(post.sec_cat_id)"
                v-on:content-vote="contentVote(index, ...arguments)"
                v-on:clip-clicked="clipClicked(index)"
                v-on:favorite-clicked="favoriteClicked(index, post.postId)"
                v-bind="post"
                :primaryChipColor="post.cat_color"
                :chipLabel="post.category"
                :dbUpvote="service.upvote"
                :dbSuperUpvote="service.superUpvote"
                :dbDownvote="service.downvote"
                :dbSuperDownvote="service.superDownvote"
                :gameId="community.game_id"
                :isMod="enableModView"
                :contentRoute="contentType"
                :duration="post.duration"
                :submitterId="post.userId"
                :submitterName1="post.submitterName1"
                :viewCount="post.view_count"
                :upvotes="post.upvotes"
                :init_upvoted="post.init_upvoted"
                :user_name="post.user_name"
                :secondaryChipLabel="post.sec_cat"
                :secondaryChipColor="post.sec_cat_color"
                :content_type="post.content_type"
                :context="post.context"
                :playerSwitch="playerSwitch"
              >
              </VideoCard>
            </v-col>
          </v-row>
        </v-tab-item>
        <!-- <v-tab-item :value="'/qtcinderella/watched'">
          <v-row wrap align-content-start class="mt-1">
            <v-col v-for="(post, index) in posts" :key=index class="three-cols-b two-cols-b one-col-b">
              <VideoCard
                v-on:content-deleted="contentDeleted(index, post.postId)"
                v-on:mark-watched="markWatched(index, post.postId, false)"
                v-on:mark-hidden="markWatched(index, post.postId, true)"
                v-on:primary-chip-selected="primaryChipSelected(post.category_id)"
                v-on:secondary-chip-selected="secondaryChipSelected(post.sec_cat_id)"
                v-bind="post"
                :primaryChipColor="post.cat_color"
                :chipLabel="post.category"
                :dbUpvote="service.upvote"
                :dbDownvote="service.downvote"
                :isMod="enableModView"
                :contentRoute="contentType"
                :duration="post.duration"
                :submitterId="post.userId"
                :submitterName1="post.submitterName1"
                :viewCount="post.view_count"
                :upvotes="post.upvotes"
                :init_upvoted="post.init_upvoted"
                :user_name="post.user_name"
                :secondaryChipLabel="post.sec_cat"
                :secondaryChipColor="post.sec_cat_color"
              >
              </VideoCard>
            </v-col>
          </v-row>
        </v-tab-item> -->
        <v-tab-item :key="3" :value="3">
          <v-row wrap align-content-start class="mt-1">
            <v-col v-for="(post, index) in posts" :key="index" :class="layout === 1  || layout === 2 ? 'one-col-c mb-8' : 'three-cols-b two-cols-b one-col-b'">
              <ImgurCard
                v-on:content-deleted="contentDeleted(index, post.postId)"
                v-on:mark-watched="markWatched(index, post.postId, false)"
                v-on:mark-hidden="markWatched(index, post.postId, true)"
                v-on:primary-chip-selected="primaryChipSelected(post.category_id)"
                v-on:secondary-chip-selected="secondaryChipSelected(post.sec_cat_id)"
                v-bind="post"
                :primaryChipColor="post.cat_color"
                :chipLabel="post.category"
                :dbUpvote="service.upvote"
                :dbDownvote="service.downvote"
                :isMod="enableModView"
                :duration="post.duration"
                :sizeSwitch="sizeSwitch"
                :submitterId="post.userId"
                :submitterName1="post.submitterName1"
                :viewCount="post.view_count"
                :upvotes="post.upvotes"
                :init_upvoted="post.init_upvoted"
                :user_name="post.user_name"
                :secondaryChipLabel="post.submitterName"
                :secondaryChipColor="post.sec_cat_color"
                :width="imgurCardWidth"
                :layout="layout"
              >
              </ImgurCard>
            </v-col>
          </v-row>
          <!-- <div v-else class="mb-8 mt-8 text-center font-italic text-h5">
            Coming Soon...
            <v-img class="mx-auto mt-6" width="112px" height="112px" :src="'https://dzbfexieadma8.cloudfront.net/communities/qtcinderella/qtc7.png'"></v-img>
          </div> -->
        </v-tab-item>
      <v-tab-item :key="4" :value="4">
          <v-row wrap align-content-start class="mt-1">
            <v-col v-for="(post, index) in posts" :key="index" :class="layout === 1  || layout === 2 ? 'one-col-c mb-8' : 'three-cols-b two-cols-b one-col-b'">
              <ImgurCard
                v-on:content-deleted="contentDeleted(index, post.postId)"
                v-on:mark-watched="unmarkWatched(index, post.postId, false)"
                v-on:mark-hidden="markWatched(index, post.postId, true)"
                v-on:primary-chip-selected="primaryChipSelected(post.category_id)"
                v-on:secondary-chip-selected="secondaryChipSelected(post.sec_cat_id)"
                v-bind="post"
                :primaryChipColor="post.cat_color"
                :chipLabel="post.category"
                :dbUpvote="service.upvote"
                :dbDownvote="service.downvote"
                :isMod="enableModView"
                :duration="post.duration"
                :sizeSwitch="sizeSwitch"
                :submitterId="post.userId"
                :submitterName1="post.submitterName1"
                :viewCount="post.view_count"
                :upvotes="post.upvotes"
                :init_upvoted="post.init_upvoted"
                :user_name="post.user_name"
                :secondaryChipLabel="post.submitterName"
                :secondaryChipColor="post.sec_cat_color"
                :width="imgurCardWidth"
                :layout="layout"
              >
              </ImgurCard>
            </v-col>
          </v-row>
          <!-- <div v-else class="mb-8 mt-8 text-center font-italic text-h5">
            Coming Soon...
            <v-img class="mx-auto mt-6" width="112px" height="112px" :src="'https://dzbfexieadma8.cloudfront.net/communities/qtcinderella/qtc7.png'"></v-img>
          </div> -->
        </v-tab-item>
      </v-tabs-items>
      <span v-else>
        <MainViewError v-if="errorState" :image="community.no_posts_image" :message="'something went wrong getting ' + contentType + ' ... please try again later'"></MainViewError>
        <MainViewError v-else-if="subpageData.route === 'winners'" :image="community.no_posts_image" message="No winners selected yet!"></MainViewError>
        <MainViewError v-else :image="community.no_posts_image" message='Be the first to submit with the + button above!'></MainViewError>
      </span>

    </v-container>
  </div>
</template>

<script>
// break the above out to another component?
import SubmissionCard from '../components/SubmissionCard'
import LoginCard from '../components/LoginCard'
import VideoCard from '../components/VideoCard'
import ImgurCard from '../components/ImgurCard'
import MainViewError from '../components/MainViewError'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import VueRouter from 'vue-router'
import urlUtilsMixin from '../utils/urlUtils'
import CommunityService from '../services/CommunityService'
import getContentService from '../utils/ContentService'
import moment from 'moment'

export default {
  name: 'QtView',

  components: { VideoCard, MainViewError, SubmissionCard, LoginCard, ImgurCard },

  mixins: [urlUtilsMixin],

  data () {
    return {
      posts: [],
      activePicker: null,
      showDateChip: false,
      datePickerDate: [],
      datePickerMenu: null,
      // datePickerPattern: /^202[1-4]-[0-1][0-9]   [0-9]{4}-[0-9]{2}-[0-9]{2}/,
      enableModView: false,
      tabModel: 0,
      sizeSwitch: false,
      playerSwitch: false,
      toDateInput: null,
      fromDateInput: null,
      sortByItems: {
        hot: {
          label: 'Hot',
          value: 'hot',
          icon: 'mdi-fire'
        },
        new: {
          label: 'New',
          value: 'new',
          icon: 'mdi-decagram-outline'
        },
        top: {
          label: 'Top',
          value: 'top',
          icon: 'mdi-transfer-up'
        }
      },
      timeItems: {
        day: {
          label: 'Today',
          query: 'day'
        },
        week: {
          label: 'Week',
          query: 'week'
        },
        month: {
          label: 'Month',
          query: 'month'
        },
        year: {
          label: 'Year',
          query: 'year'
        },
        all: {
          label: 'All Time',
          query: 'all'
        }
      },
      durationItems: [
        {
          text: 'All Durations',
          value: null
        },
        {
          text: 'Less than 10 mins',
          value: 10
        },
        {
          text: '10 - 30 mins',
          value: 30
        },
        {
          text: '30 mins - 1 hr',
          value: 60
        },
        {
          text: 'More than 1 hr',
          value: 61
        }
      ],
      duration: undefined,
      offset: 0,

      // getVideos error
      errorState: 0,
      sortBy: this.sortByInit,

      // should set up category like secCategory rather than the init object
      category: this.categoryInit,
      secCategory: null,
      timeDataInit: this.timeInit,
      reloadBusy: true,
      deleteCount: 0,
      layout: 0,
      community: {
        subpages: [
          {
            route: 'not_found',
            submitUserTitleLabel: 'not found',
            submitTitleLabel: 'not found',
            submitErrorLabel: 'not found',
            submitInputLabel: 'not found',
            categories: [],
            data: {},
            order: 0
          }
        ],
        data: {
          creators: []
        }
      },
      liveProps: {
        borderColorStyle: '',
        isLive: false
      },
      creatorFilter: null
    }
  },

  methods: {

    ...mapActions(['triggerErrorNotification']),

    ...mapMutations(['setErrorNotification', 'setSuccessNotification', 'setBanNotification', 'setEmoteNotification', 'setVDialog', 'setPlayerInfo']),

    uploadVideo: async function (video) {
      try {
        await this.service.uploadContent(video.url, video.category_id, this.community.game_id, video.sec_cat_id, video.title, video.context, this.subpageData.order, video.santa)
        this.sortBy = 'new'
        // set to category of uploaded content
        // this.category = video.category
        this.category = this.categories[0]
        this.secCategory = null
        this.setVDialog(false)

        this.routeSelectorChange()
        this.setSuccessNotification({ trigger: true })
      } catch (error) {
        if (error.response && error.response.status === 400) {
          this.setEmoteNotification(true)
        } else if (error.response && error.response.status === 403) {
          this.setVDialog(false)
          this.setBanNotification(true)
        } else if (error.response && error.response.status === 409) {
          this.setErrorNotification({ trigger: true, message: 'This has already been submitted ):' })
        } else if (error.response && error.response.status === 422) {
          this.setErrorNotification({ trigger: true, message: 'Only clips from twitch.tv/AlveusSanctuary can be submitted' })
        } else if (error.response && error.response.status === 414) {
          this.setErrorNotification({ trigger: true, message: 'This looks a little long to be a clip, maybe submit it under videos' })
        } else if (error.response && error.response.status === 405) {
          this.setErrorNotification({ trigger: true, message: 'Sorry, submissions have been closed' })
        } else {
          this.setErrorNotification({ trigger: true })
        }
      }
    },

    getVideos: async function () {
      try {
        var timeParam = null

        if (this.sortBy === 'top') {
          timeParam = this.time.query
        }

        const fromDate = this.datePickerDate[0]
        const toDate = this.datePickerDate[1]
        const res = await this.service.getContent(this.sortBy, this.category.category_id, this.offset, timeParam, this.community.game_id, this.duration, null, this.secCategory, this.subpageData.data.is_watched_tab, this.creatorFilter, this.subpageData.order, fromDate, toDate)
        this.posts = this.posts.concat(res.data)
        this.offset += res.data.length
        if (this.reloadBusy) {
          this.reloadBusy = false
        }
      } catch (err) {
        this.errorState = 1
      }
    },

    // push the route on change, watcher on route causes the reload. On duplicated nav failure manually reload
    routeSelectorChange: async function () {
      var routeConfig = '/' + this.gameName + '/' + this.contentType + '/' + this.sortBy
      if (this.sortBy === 'top') {
        routeConfig = routeConfig + '?t=' + this.time.query
      }

      this.$router.push(routeConfig).catch(async failure => {
        const { isNavigationFailure, NavigationFailureType } = VueRouter
        if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
          // if nav is duplicated, why reload? maybe just catch error and do nothing?
          this.offset = 0
          this.posts = []
          this.reloadBusy = true
          await this.getVideos()
        }
      })
    },

    // use reload content instead
    // categoryChange: async function () {
    //   this.offset = 0
    //   this.posts = []
    //   this.reloadBusy = true
    //   await this.getVideos()
    // },

    primaryChipSelected: function (categoryId) {
      // there will never be thaat many cats... just find in the array
      if (categoryId !== this.category.category_id) {
        this.category = this.categories.find(el => el.category_id === categoryId)
        this.reloadContent()
      }
    },

    secondaryChipSelected: function (categoryId) {
      // there will never be thaat many cats... just find in the array
      if (categoryId !== this.secCategory) {
        this.secCategory = this.community.secondaryCategories.find(el => el.category_id === categoryId).category_id
        this.reloadContent()
      }
    },

    datePickerApply: function () {
      if (this.$refs.fromDateInput.validate() && this.$refs.toDateInput.validate()) {
        this.datePickerMenu = false
        if (this.fromDateInput) {
          this.datePickerDate[0] = this.fromDateInput
        }
        if (this.toDateInput) {
          this.datePickerDate[1] = this.toDateInput
        }
        // what if they didn't select dates or only selected one
        if (this.datePickerDate.length > 0) {
          this.showDateChip = true
          this.reloadContent()
        }
      }
    },

    datePickerCancel: function () {
      this.datePickerMenu = !this.datePickerMenu
    },

    datePickerReset: function () {
      this.datePickerDate = []
      this.fromDateInput = null
      this.toDateInput = null
      this.$refs.fromDateInput.resetValidation()
      this.$refs.toDateInput.resetValidation()
      this.showDateChip = false
      this.reloadContent()
    },

    datePickerInputRule: function (value) {
      return moment(value, 'YYYY-MM-DD', true).isValid()
    },

    dateInput: function (value) {
      if (value) {
        if (value.length > 0) {
          this.fromDateInput = value[0]
          this.toDateInput = null
          this.$refs.toDateInput.resetValidation()
          if (value.length > 1) {
            this.toDateInput = value[1]
          }
        }
      }
    },

    // on content-deleted emit event from VideoCard
    contentDeleted: async function (deleteIndex, postId) {
      try {
        await this.service.deleteContent(postId)
        this.posts.splice(deleteIndex, 1)
        this.deleteCount++
        this.offset--
      } catch (error) {
        this.setErrorNotification({ trigger: true })
      }
    },

    markWatched: async function (index, postId, hide, value) {
      try {
        await this.service.markWatched(postId, this.community.game_id, hide, value)
        if (this.contentType !== 'emotes') {
          if (!this.subpageData.data.is_watched_tab) {
            this.posts.splice(index, 1)
            this.deleteCount++
            this.offset--
          } else {
            this.posts[index].watched = !this.posts[index].watched
          }
        } else {
          this.setSuccessNotification({ trigger: true, message: 'Congrats!' })
        }
      } catch (error) {
        this.setErrorNotification({ trigger: true })
      }
    },

    unmarkWatched: async function (index, postId, hide) {
      try {
        await this.service.markWatched2(postId, this.community.game_id, hide)
        this.posts.splice(index, 1)
        this.deleteCount++
        this.offset--
        this.setSuccessNotification({ trigger: true, message: 'Sadge no longer a winner' })
      } catch (error) {
        this.setErrorNotification({ trigger: true })
      }
    },

    contentVote: function (index, upvotes, upvoted, downvoted, modUpvoted, modDownvoted) {
      if (upvoted) {
        if (modUpvoted) {
          this.posts[index].init_upvoted = 5
        } else {
          this.posts[index].init_upvoted = 1
        }
      } else if (downvoted) {
        if (modDownvoted) {
          this.posts[index].init_upvoted = -2
        } else {
          this.posts[index].init_upvoted = -1
        }
      } else {
        this.posts[index].init_upvoted = null
      }
      this.posts[index].upvotes = upvotes.toString()
    },

    openLink: function (link) {
      window.open(link, '_blank')
    },

    closeSubs: async function () {
      await this.service.closeSubs()
      this.setSuccessNotification({ trigger: true, message: 'Submissions Closed' })
    },

    reloadContent: async function () {
      this.offset = 0
      this.deleteCount = 0
      this.posts = []
      this.reloadBusy = true
      await this.getVideos()
    },

    clipClicked: async function (index) {
      this.setPlayerInfo({
        community: this.community,
        posts: this.posts,
        postIndex: index,
        modEnabled: this.enableModView,
        offset: this.offset,
        creatorFilter: this.creatorFilter,
        creatorList: this.creatorList,
        categoryId: this.category.category_id
      })

      var routeConfig = '/' + this.gameName + '/player/' + this.contentType + '/' + this.sortBy
      if (this.sortBy === 'top') {
        routeConfig = routeConfig + '?t=' + this.time.query
      }
      this.$router.push(routeConfig)
    },

    // used for date picker
    save (date) {
      this.$refs.menu.save(date)
    },

    favoriteClicked: async function (index, postId) {
      await this.service.favoriteContent(postId)
      this.posts[index].favorited = !this.posts[index].favorited
    }
  },

  props: {
    // categoryInit: {
    //   type: String,
    //   default: 'All'
    // },
    sortByInit: {
      type: String,
      default: 'hot'
    },
    timeInit: {
      type: String,
      default: 'week'
    },
    categoryInit: {
      type: Object,
      default: function () {
        return {
          name: 'All Categories',
          game_id: 1,
          category_id: null,
          color: 'grey'
        }
      }
    },
    secCatInit: {
      type: Object,
      default: function () {
        return {
          name: 'All',
          game_id: 1,
          category_id: null,
          color: 'grey'
        }
      }
    },
    gameName: String,
    errorInit: Boolean,
    // service: Object,
    contentType: {
      type: String,
      default: 'clips'
    }
  },

  computed: {
    ...mapGetters(['userDetails', 'getCommunity']),

    // caluculated every time contentType changes
    categories: function () {
      return [this.categoryInit, ...this.subpageData.categories]
    },

    submitCategories: function () {
      return this.subpageData.categories.filter(cat => !cat.sortOnly)
    },

    secondaryCategories: function () {
      return [this.secCatInit, ...this.community.secondaryCategories]
    },

    subpageData: function () {
      return this.community.subpages[this.tabModel] || {
        route: '',
        order: 0
      }
    },

    liveLink: function () {
      if (this.community.game_id === 13) {
        return 'https://twitch.tv/AlveusSanctuary'
      } else {
        return 'https://twitch.tv/' + this.community.routeParam
      }
    },

    creatorList: function () {
      if (this.contentType === 'santa') {
        return ['All Creators', 'Brittt', 'erobb221', 'HasanAbi', 'Ludwig', 'Maya', 'Myth', 'Nmplol', 'prezoh', 'QTCinderella', 'Squeex', 'Stanz', 'willneff']
      }
      if (this.community.data && this.community.data.creators) {
        return ['All Creators', ...this.community.data.creators]
      } else {
        return ['All Creators']
      }
    },

    contentGridType: function () {
      if (this.subpageData) {
        if (this.subpageData.type === 1 || this.subpageData.type === 2) {
          return 0
        } else if (this.subpageData.type === 3 && this.subpageData.route === 'winners') {
          return 4
        } else {
          return this.subpageData.type
        }
      }
      return 0
    },

    service: function () {
      return getContentService(this.subpageData.type)
    },

    // contentGridType: function () {
    //   const subpage = this.community.subpages.find(el => el.route === this.contentType)
    //   return subpage.order
    // },

    isMod: function () {
      if (this.userDetails && this.community && this.userDetails.mod_comms) {
        for (const id of this.userDetails.mod_comms) {
          if (id === this.community.game_id) {
            return true
          }
        }
      }
      return false
    },

    vDialog: {
      get () {
        return this.$store.state.twitterAuth.vDialog
      },

      set (value) {
        // this.$store.commit('setVDialog', value)
        this.setVDialog(value)
      }
    },

    errorNotification: {
      get () {
        return this.$store.state.twitterAuth.errorNotification
      },

      set (value) {
        this.setErrorNotification({ trigger: value })
      }
    },

    successNotification: {
      get () {
        return this.$store.state.twitterAuth.successNotification
      },

      set (value) {
        this.setSuccessNotification({ trigger: value })
      }
    },

    // hmmm
    shortCategory: function () {
      if (this.$vuetify.breakpoint.xs && this.sortBy === 'top' && this.category.name.length > 4) {
        return true
      } else {
        return false
      }
    },

    timeDisplay: function () {
      return this.sortBy === 'top' ? 'ml-n1 mr-3 ml-sm-n4 mr-sm-6' : 'd-none'
    },

    time: {
      get () {
        return this.timeItems[this.timeDataInit]
      },
      set (newObj) {
        this.timeDataInit = newObj.query
        return newObj
      }
    },

    imgurCardWidth: function () {
      if (this.layout === 1 || this.layout === 2) {
        return '365px'
      } else {
        return ''
      }
    },

    bannerMaxHeight: function () {
      if (this.gameName === 'qtcinderella') {
        return 230
      } else if (this.gameName === 'nmplol') {
        return 300
      } else if (this.gameName === 'avghans') {
        return 250
      } else {
        return 200
      }
    },

    bannerMinHeight: function () {
      if (this.gameName === 'squeex') {
        return 200
      } else {
        return 64
      }
    }
  },

  // beforeMount was here before, why?
  created: async function () {
    if (this.errorInit) {
      this.setErrorNotification({ trigger: this.errorInit })
    }

    var resp
    try {
      resp = await CommunityService.getCommunity(this.gameName)
    } catch (error) {
      if (error.response.status === 404) {
        this.$router.push({ name: 'sadge' })
      }
    }

    if (resp) {
      this.community = resp.data
      if (this.community.data && this.community.data.creators) {
        this.community.data.creators = this.community.data.creators.filter(item => item !== 'Atrioc')
      }
      this.liveProps = this.community.data.liveProps
      const subpage = this.community.subpages.find(el => el.route === this.contentType)

      if (subpage) {
        this.tabModel = subpage.order
      } else {
        this.$router.push({ name: 'sadge' })
      }

      this.reloadBusy = true
      await this.getVideos()
    }
  },

  watch: {
    $route (to, from) {
      if (to.params.sortByUrl) {
        this.sortBy = to.params.sortByUrl
      }

      // can optimize this
      if (to.params.content !== from.params.content) {
        this.category = this.categoryInit
      }
      if (to.params.content !== 'videos') {
        this.duration = undefined
      }

      this.offset = 0
      this.deleteCount = 0
      this.posts = []
      this.reloadBusy = true
      this.getVideos()
    },

    deleteCount: function (value) {
      if (value > 12) {
        this.deleteCount = 0
        this.reloadBusy = true
        this.getVideos()
      }
    },

    // update route on tab change -> route watcher triggers reload
    tabModel: function (to, from) {
      var sortByParam = ''
      if (this.$router.currentRoute.params && this.$router.currentRoute.params.sortByUrl) {
        sortByParam = '/' + this.$router.currentRoute.params.sortByUrl
      }
      this.$router.push('/' + this.gameName + '/' + this.subpageData.route + sortByParam).catch(failure => {
        const { isNavigationFailure, NavigationFailureType } = VueRouter
        if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
          // if nav is duplicated, do nothing
        }
      })
    }
  }
}
</script>

<style scoped>

  .one-col-c {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }

  .v-input .v-label {
    font-size: 14px;
  }

  .live-marker {
    background: crimson;
    position: relative;
    z-index: 60;
    /* height: 20px;
    width: 40px; */
    height: 23.5px;
    width: 43.5px;
    text-transform: uppercase;
    border-radius: 15%/25%;
    line-height: 16px;
    text-align: center;
    border: 2px solid #000;
    font-family: sans-serif;
    font-size: 13px;
    padding-top: 1px;
    top: -15px;
    left: 50%;
    margin-left: -22px;
    text-shadow: 1px 0 0 rgba(0,0,0,0.4);
  }

  .watched-button {
    color: #0e0e10;
  }

  /* #qtview {
    background-image: "https://dzbfexieadma8.cloudfront.net/communities/streamerawards/default-clean.jpg"
  } */

  /* used to be 1099, why? */
  @media only screen and (min-width: 991px) {
    .four-cols-b {
      width: 25%;
      max-width: 25%;
      flex-basis: 25%;
    }
  }

  @media only screen and (min-width: 991px) {
    .three-cols-b {
      width: 33%;
      max-width: 33%;
      flex-basis: 33%;
    }
  }
  @media only screen and (min-width: 601px) and (max-width: 990px) {
    .two-cols-b {
      width: 50%;
      max-width: 50%;
      flex-basis: 50%;
    }
  }
  @media only screen and (max-width: 600px) {
    .one-col-b {
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
    }
  }

  @media only screen and (min-width: 1600px) and (max-width: 2000px) {
    .five-cols {
      width: 20%;
      max-width: 20%;
      flex-basis: 20%;
    }

    .selector {
      width: 40%;
    }

  }

  @media only screen and (min-width: 1300px) and (max-width: 1599px) {
    .four-cols {
      width: 25%;
      max-width: 25%;
      flex-basis: 25%;
    }
  }

  @media only screen and (min-width: 990px) and (max-width: 1301px) {
    .three-cols {
      width: 33%;
      max-width: 33%;
      flex-basis: 33%;
    }
  }

  @media only screen and (min-width: 600px) and (max-width: 989px) {
    .two-cols {
      width: 50%;
      max-width: 50%;
      flex-basis: 50%;
    }
  }

  @media only screen and (max-width: 599px) {
    .one-col {
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
    }
  }
</style>
